<template>
  <v-container
    fluid
    :class="`py-0 ma-0 ${$vuetify.breakpoint.mobile ? 'px-4' : 'px-8'}`"
    v-resize="onResize"
  >
    <v-toolbar
      :class="`mx-0 mt-4 pa-0 sign-toolbar ${
        $vuetify.breakpoint.mobile ? 'd-flex flex-column' : ''
      }`"
      flat
      dense
    >
      <v-toolbar-title
        :class="`${
          $vuetify.breakpoint.md
            ? 'flex-column'
            : 'd-flex align-center text-wrap'
        } text-h5 font-weight-medium mx-0 px-0 mr-2 mb-0`"
        id="v-step-0"
      >
        Modelos de mensagem de férias
      </v-toolbar-title>

      <v-btn
        v-if="!$vuetify.breakpoint.mobile"
        @click="getAll"
        icon
        :disabled="search !== '' || loading"
      >
        <v-icon v-text="'ph-arrow-clockwise'" />
      </v-btn>
      <v-tooltip v-if="!$vuetify.breakpoint.mobile" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="showListStyle = !showListStyle"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="search !== '' || loading"
          >
            <v-icon
              v-text="showListStyle ? 'ph-squares-four' : 'ph-list-dashes'"
            />
          </v-btn>
        </template>
        <span>{{
          showListStyle ? "Visualizar em grade" : "Visualizar em lista"
        }}</span>
      </v-tooltip>
      <v-spacer />
      <v-btn
        color="primaryControl"
        class="text-none text-body-1 font-weight-medium my-1"
        outlined
        :href="goToControlUsersPage"
        :fab="$vuetify.breakpoint.xs"
        large
        target="_blank"
      >
        <div style="max-width: 32px">
          <Logo product="conecta_control" width="28" no-margins class="mr-2" />
        </div>
        <span v-if="$vuetify.breakpoint.mdAndUp">
          Bloquear acesso dos usuários
        </span>
      </v-btn>

      <v-btn
        color="accent"
        elevation="0"
        class="ml-4 text-none text-body-1 font-weight-medium"
        dark
        large
        :fab="$vuetify.breakpoint.xs"
        @click="redirectToNewTemplate"
        data-testid="btn-new-model-vacation"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp" v-text="'ph-plus'" />
        <span v-if="$vuetify.breakpoint.smAndUp">Criar novo modelo</span>
      </v-btn>
    </v-toolbar>
    <v-row class="fill-height mt-2">
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5" ref="panels">
        <ResizablePage hide-footer>
          <template v-slot:table="tableProps">
            <v-card flat class="pa-0 ma-0" :max-height="tableProps.tableHeight">
              <v-card-actions
                v-if="!$vuetify.breakpoint.mobile"
                class="mb-5 px-0"
                id="v-step-6"
              >
                <v-text-field
                  label=" Pesquisar modelo"
                  prepend-inner-icon="ph-magnifying-glass"
                  color="accent"
                  v-model="search"
                  hide-details
                  solo
                ></v-text-field>
              </v-card-actions>

              <!-- VERSÃO MOBILE -->

              <v-card-text
                v-if="!$vuetify.breakpoint.mobile && search"
                class="pa-0 ma-0"
              >
                <v-card
                  v-if="search && filteredTemplates.length > 0"
                  flat
                  id="filteredTemplates"
                  class="overflow-y-auto"
                  :height="tableProps.tableHeight + 88"
                >
                  <CardTemplate
                    v-for="(template, i) in filteredTemplates"
                    :key="i"
                    :info="template"
                    :custom="!template.is_default"
                    @selectCard="selectedTemplateCard = template"
                    :selected="verifyIfIsSelected(template)"
                    list-style
                    class="mb-4"
                    @edit="redirectEditTemplate(template)"
                    @duplicate="duplicate(template)"
                    @delete="
                      (dialogDeleteTemplate = true),
                        (template_to_delete = template)
                    "
                  />
                </v-card>
                <div v-else class="disabled--text text-center">
                  Nenhum resultado para a pesquisa
                </div>
              </v-card-text>

              <v-card-text class="pa-0 ma-0">
                <v-card-subtitle
                  class="px-0 ma-0 text-subtitle-2"
                  v-if="$vuetify.breakpoint.mobile"
                >
                  Todos os modelos
                </v-card-subtitle>
                <div
                  id="v-step-1"
                  style="width: 100%"
                  v-if="$vuetify.breakpoint.mobile"
                >
                  <v-autocomplete
                    v-model="mobileSelectModel"
                    :hide-no-data="!allVacationTemplates"
                    :items="allVacationTemplatesWithLabels"
                    :search-input.sync="search"
                    label="Listando todos os modelos de assinatura de e-mail"
                    solo
                    item-text="name"
                    item-color="accent"
                    return-object
                    hide-details
                    full-width
                    height="60"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon
                        color="accent"
                        left
                        class="pl-1"
                        v-text="
                          item.is_default
                            ? 'ph-fill ph-article'
                            : 'ph-fill ph-note-pencil'
                        "
                        dense
                      />
                      <span class="text-truncate">{{ item.name }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon
                        color="accent"
                        left
                        v-text="
                          item.is_default
                            ? 'ph-fill ph-article'
                            : 'ph-fill ph-note-pencil'
                        "
                        dense
                      />
                      <span class="text-truncate">{{ item.name }}</span>
                    </template>
                  </v-autocomplete>
                </div>
                <v-expansion-panels
                  v-else-if="!search && !$vuetify.breakpoint.mobile"
                  v-model="expandTemplatesTypes"
                  :disabled="loading"
                  flat
                  mandatory
                  accordion
                >
                  <v-expansion-panel class="outlined">
                    <v-expansion-panel-header
                      id="v-step-2"
                      class="text-h6 font-weight-medium"
                      :hide-actions="expandTemplatesTypes === 0"
                    >
                      Personalizados
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ma-0 pa-0">
                      <v-card
                        flat
                        class="ma-0 pa-0 overflow-y-auto"
                        :max-height="panelHeight"
                        min-height="190"
                        id="customTemplates"
                        :disabled="loading"
                      >
                        <v-card-text class="px-0" v-if="!companyHasTemplates">
                          Nenhum modelo de mensagem de férias personalizado
                        </v-card-text>
                        <v-card-text
                          v-else
                          class="ma-0 pa-0"
                          id="customTemplatesArea"
                        >
                          <!-- ÁREA DE MODELOS PERSONALIZADOS -->
                          <v-row class="ma-0 pa-0">
                            <v-col
                              :lg="showListStyle ? 12 : 6"
                              v-for="(
                                companyTemplate, i
                              ) in companyVacationTemplates"
                              :key="i"
                              class="ma-0 px-0 pr-2"
                            >
                              <CardTemplate
                                :loading="loading"
                                :info="companyTemplate"
                                isVacationTemplate
                                :htmlPreview="
                                  fluxStringToTemplate(currentSelected.content)
                                "
                                custom
                                @selectCard="
                                  selectedTemplateCard = companyTemplate
                                "
                                :selected="verifyIfIsSelected(companyTemplate)"
                                :list-style="showListStyle"
                                @edit="redirectEditTemplate(companyTemplate)"
                                @duplicate="duplicate(companyTemplate)"
                                @delete="
                                  (dialogDeleteTemplate = true),
                                    (template_to_delete = companyTemplate)
                                "
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="outlined mt-4">
                    <v-expansion-panel-header
                      id="v-step-3"
                      class="text-h6 font-weight-medium"
                      :hide-actions="expandTemplatesTypes === 1"
                    >
                      Padrão
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card
                        flat
                        class="ma-0 pa-0 overflow-y-auto"
                        :max-height="panelHeight"
                        min-height="190"
                        id="defaultTemplates"
                        :disabled="loading"
                      >
                        <v-card-text class="px-0" v-if="!hasDefaultTemplates">
                          Nenhum modelo de mensagem de férias padrão
                        </v-card-text>
                        <v-card-text v-else class="ma-0 pa-0">
                          <!-- ÁREA DE MODELOS PADRÃO -->
                          <v-row class="ma-0 pa-0">
                            <v-col
                              :lg="showListStyle ? 12 : 6"
                              v-for="(
                                defaultTemplate, i
                              ) in defaultVacationTemplates"
                              :key="i"
                              class="ma-0 px-0 pr-2"
                            >
                              <CardTemplate
                                :info="defaultTemplate"
                                isVacationTemplate
                                :htmlPreview="
                                  fluxStringToTemplate(defaultTemplate.content)
                                "
                                @selectCard="
                                  selectedTemplateCard = defaultTemplate
                                "
                                :selected="verifyIfIsSelected(defaultTemplate)"
                                :list-style="showListStyle"
                                @edit="redirectEditTemplate(defaultTemplate)"
                                @duplicate="duplicate(defaultTemplate)"
                                @delete="
                                  (dialogDeleteTemplate = true),
                                    (template_to_delete = defaultTemplate)
                                "
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </template>
        </ResizablePage>
      </v-col>

      <!-- PREVIEW -->
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 7">
        <ResizablePage
          outlined
          styles="pa-4"
          bodyStyle="px-3 pb-1"
          :resizable-max-width="false"
        >
          <template v-slot:header>
            <v-skeleton-loader
              v-if="loading"
              max-width="300"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-else>
              <v-card-title
                class="text-wrap"
                v-text="
                  $vuetify.breakpoint.mdAndUp
                    ? `${$t('common.viewingModel')} ${selectedName}`
                    : $t('common.viewingModel')
                "
              />
              <v-card-subtitle
                v-if="currentSelected && !$vuetify.breakpoint.mobile"
                v-text="
                  `${selectedDescription} ${
                    selectedDescription !== '' ? '•' : ''
                  }  Última atualização em ${formatDateAndHour(
                    currentSelected.updated_at,
                    true
                  )}`
                "
              />
            </div>
          </template>
          <template v-slot:table="tableProps">
            <TemplatePreview
              :html="hasHtml"
              :subject="currentSelected.response_subject"
              :loading="loading"
              :title="selectedName"
              hide-bottom
              :maxHeight="
                $vuetify.breakpoint.mobile ? '' : tableProps.tableHeight - 20
              "
              show-expand
              id="v-step-4"
              isVacationTemplate
              show-user-selection
            />
          </template>
          <!-- OPÇÕES DO MODELO -->
          <template v-slot:footer>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="6"
                xl="4"
                :order="$vuetify.breakpoint.mdAndDown ? 1 : 0"
              >
                <v-card-actions class="px-0 py-1 ma-0">
                  <v-menu offset-y top left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="loading"
                        id="v-step-7"
                        color="accent"
                        :class="`text-none px-4 text-subtitle-2 mr-4`"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!currentSelected"
                        @click="goBatchApply(currentSelected)"
                      >
                        Ir para aplicação
                      </v-btn>
                    </template>
                  </v-menu>

                  <v-menu offset-y top left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="loading"
                        data-testid="editMenu"
                        color="accent"
                        :class="`text-none text-subtitle-2 px-3`"
                        text
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        id="v-step-8"
                        :disabled="!currentSelected"
                      >
                        Editar
                        <v-divider class="ml-2" vertical />
                        <v-icon
                          v-text="'ph-fill ph-caret-down'"
                          right
                          class="my-auto"
                        />
                      </v-btn>
                    </template>
                    <v-list width="200" dense>
                      <v-list-item
                        link
                        v-if="isCustom"
                        @click="redirectEditTemplate(currentSelected)"
                      >
                        <v-list-item-title data-testid="editButton">
                          Editar modelo
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="duplicate(currentSelected)">
                        <v-list-item-title> Duplicar modelo </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="!$vuetify.breakpoint.lgAndUp && isCustom"
                        link
                        @click="
                          (dialogDeleteTemplate = true),
                            (template_to_delete = currentSelected)
                        "
                      >
                        <v-list-item-title> Excluir modelo </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-col>
              <v-col md="12" lg="6" xl="8" class="ml-auto">
                <v-card-actions class="ma-0 pa-0">
                  <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
                  <v-switch
                    :disabled="!currentSelected || loading"
                    v-if="isConecta && currentSelected"
                    :class="`py-0 my-0 ${
                      $vuetify.breakpoint.mobile ? 'pl-2' : ''
                    } mr-5`"
                    :value.sync="selectedType"
                    @change="changeModelStatus(currentSelected.key)"
                    :true-value="'default'"
                    :false-value="'custom'"
                    :loading="loading"
                    :label="`Modelo padrão`"
                    color="accent"
                    inset
                    id="v-step-9"
                    hide-details
                  ></v-switch>
                  <v-spacer v-if="!$vuetify.breakpoint.xl && isConecta" />
                  <v-tooltip
                    bottom
                    v-if="isCustom && $vuetify.breakpoint.lgAndUp"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!currentSelected || loading"
                        :loading="loading"
                        @click="
                          (dialogDeleteTemplate = true),
                            (template_to_delete = currentSelected)
                        "
                        v-bind="attrs"
                        v-on="on"
                        :class="`my-auto text-none font-weight-medium`"
                        id="v-step-10"
                        icon
                        large
                        color="grey darken-1"
                      >
                        <v-icon v-text="'ph-trash'" />
                      </v-btn>
                    </template>
                    <span>Excluir modelo</span>
                  </v-tooltip>
                </v-card-actions>
              </v-col>
            </v-row>
          </template>
        </ResizablePage>
      </v-col>
    </v-row>

    <!-- DIALOG: CONFIRMAR EXCLUSÃO DE UM TEMPLATE -->
    <ConfirmationDialog
      :show="dialogDeleteTemplate"
      :target="selectedToDelete.name"
      action-text="Excluir modelo de mensagem de férias"
      :loading="actionLoading"
      :width="userWithTemplateApplied.length ? '700' : '500'"
      @close="dialogDeleteTemplate = false"
      @ok="deleteVacationTemplate(selectedToDelete.key)"
      hideReversibleText
    >
      <div v-if="userWithTemplateApplied.length">
        <Alert prominent color="warning">
          A exclusão não reflete na mensagem de férias dos usuários abaixo,
          porém os mesmos ficarão sem um modelo de mensagem de férias atribuído
          no Conecta Suite.
        </Alert>
        <div class="users-box outlined my-4 pa-2">
          <v-chip
            v-for="user in userWithTemplateApplied"
            :key="user.email"
            pill
            class="ma-1"
          >
            <v-avatar left>
              <v-img :src="user.photo"></v-img>
            </v-avatar>
            <strong>{{ user.email }}</strong>
          </v-chip>
        </div>
      </div>
    </ConfirmationDialog>
  </v-container>
</template>

<script>
import ResizablePage from "@/components/base/ResizablePage.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import CardTemplate from "@/components/sign/general/CardTemplate";
import TemplatePreview from "@/components/sign/general/TemplatePreview";
import { fluxStringToTemplate } from "@/helpers/services/tagPreview";
import { formatDateAndHour } from "@/helpers/services/utils";
import { snackbarMessages } from "@/helpers/variables/snackbarMessages";
import { messages } from "@/helpers/variables/templates";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "MessageTemplates",
  components: {
    ResizablePage,
    ConfirmationDialog,
    CardTemplate,
    TemplatePreview,
  },
  data: () => ({
    panelHeight: 0,
    messages,
    mobileSelectModel: null,
    search: "",
    showListStyle: false,
    expandTemplatesTypes: 0,
    selectedTemplateCard: null,
    template_to_delete: null,
    actionLoading: false,
    dialogDeleteTemplate: false,
    newTemplatePath: "/sign/templates/vacation-message/new",
    snackbarMessages,
  }),

  computed: {
    ...mapGetters([
      "loading",
      "companyVacationTemplates",
      "defaultVacationTemplates",
      "allVacationTemplates",
      "allVacationTemplatesWithLabels",
      "token",
      "isConecta",
      "users",
    ]),

    userWithTemplateApplied() {
      return this.users.filter(
        (user) => user.applied_vacation_key == this.selectedToDelete.key
      );
    },

    hasHtml() {
      if (this.currentSelected.content) {
        return this.fluxStringToTemplate(this.currentSelected.content);
      }

      return "";
    },

    goToControlUsersPage() {
      return (
        process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL +
        "/users?vacation=true"
      );
    },

    companyHasTemplates() {
      return this.companyVacationTemplates.length;
    },

    hasDefaultTemplates() {
      return this.defaultVacationTemplates.length;
    },

    currentSelected() {
      return this.selectedTemplateCard || {};
    },

    selectedToDelete() {
      return this.template_to_delete || {};
    },

    selectedType() {
      return this.currentSelected.is_default ? "default" : "custom";
    },

    selectedDescription() {
      return this.currentSelected ? this.currentSelected.description : "";
    },

    isCustom() {
      return this.currentSelected && this.currentSelected.is_default === false;
    },

    selectedName() {
      return this.currentSelected ? this.currentSelected.name : "";
    },

    filteredTemplates() {
      return this.allVacationTemplates.filter((item) =>
        item.name.toUpperCase().includes(this.search.toUpperCase())
      );
    },
  },

  methods: {
    ...mapActions([
      "getVacationTemplates",
      "changeVacationModelType",
      "createNewVacationTemplate",
    ]),
    ...mapMutations(["setSnackBar", "setLoading"]),

    formatDateAndHour,

    fluxStringToTemplate,

    redirectToNewTemplate() {
      this.$router.push({ path: this.newTemplatePath });
    },

    async getAll() {
      this.setLoading(true);
      await this.getVacationTemplates();
      this.setLoading(false);
    },

    verifyIfIsSelected(template) {
      return this.currentSelected
        ? this.currentSelected.key === template.key
        : false;
    },

    async duplicate(template = null) {
      this.setLoading(true);
      const {
        content,
        description,
        is_default,
        is_html,
        name,
        response_subject,
      } = template || this.currentSelected;

      const payload = {
        name: `[CÓPIA] ${name}`,
        description,
        response_subject,
        content,
        is_html,
        is_default,
      };

      const snackbarConfig = { show: true };

      await this.createNewVacationTemplate(payload)
        .then((response) => {
          snackbarConfig.message = this.snackbarMessages.new_vacation_model;
          this.setSnackBar(snackbarConfig);
          this.getAll();
          this.redirectEditTemplate(response.data);
        })
        .catch((response) => {
          const { data } = response;
          snackbarConfig.message_key = data.code;
          snackbarConfig.is_key = true;
          snackbarConfig.color = "error";
          this.setSnackBar(snackbarConfig);
        })
        .finally(() => {
          this.setLoading(false);
        });
    },

    redirectEditTemplate(item) {
      this.selectedTemplateCard = item;
      this.$router.push(`/sign/templates/vacation-message/${item.key}`);
    },

    onResize() {
      if (!this.$vuetify.breakpoint.mobile) {
        this.panelHeight =
          window.innerHeight -
          this.$refs.panels.getBoundingClientRect().y -
          53 * 5.5;
      }
    },

    async deleteVacationTemplate(key) {
      this.setLoading(true);
      this.actionLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/templates/${key}`;
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      const snackbarConfig = { show: true };
      await this.$axios
        .delete(url, auth)
        .then(() => {
          snackbarConfig.message = this.snackbarMessages.operation_sucess;
          this.setSnackBar(snackbarConfig);
          this.getVacationTemplates();
          this.setFirstAsSelected();
          if (key === this.companyVacationTemplates[0].key) {
            this.selectedTemplateCard = this.defaultVacationTemplates[0];
          }
        })
        .catch((err) => {
          const { data } = err.response;
          snackbarConfig.message_key = data.code;
          snackbarConfig.is_key = true;
          snackbarConfig.color = "error";
          this.setSnackBar(snackbarConfig);
        })
        .finally(() => {
          this.actionLoading = false;
          this.dialogDeleteTemplate = false;
          this.setLoading(false);
        });
    },

    setFirstAsSelected() {
      let domElement = null;

      if (this.companyHasTemplates > 0) {
        this.selectedTemplateCard = this.companyVacationTemplates[0];
        this.mobileSelectModel = this.companyVacationTemplates[0];
        this.expandTemplatesTypes = 0;
        domElement = document.getElementById("customTemplates");
      } else {
        this.selectedTemplateCard = this.defaultVacationTemplates[0];
        this.mobileSelectModel = this.defaultVacationTemplates[0];
        this.expandTemplatesTypes = 1;
        domElement = document.getElementById("defaultTemplates");
      }

      if (domElement) {
        domElement.scrollTo({ top: 0, behavior: "smooth" });
      }
    },

    async changeModelStatus(model_id) {
      this.setLoading(true);
      await this.changeVacationModelType(model_id)
        .then(({ data }) => {
          this.selectedTemplateCard = data;
          const isCustom = this.selectedTemplateCard.is_default;
          this.expandTemplatesTypes = isCustom ? 0 : 1;
          this.getAll();
          this.setSnackBar({
            show: true,
            color: "success",
            message: !isCustom
              ? messages.success_removing_default
              : messages.success_making_default,
          });
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: messages.error_change_default,
          });
        })
        .finally(() => {
          this.setLoading(false);
        });
    },

    goBatchApply(item) {
      this.setLoading(true);
      this.selectedTemplateCard = item;
      this.$router.push({
        path: "/sign/users/apply",
        query: {
          key: item.key,
        },
      });
      this.setLoading(false);
    },
  },
  watch: {
    mobileSelectModel() {
      if (this.mobileSelectModel) {
        this.selectedTemplateCard = Object.assign({}, this.mobileSelectModel);
      }
    },
  },

  async beforeMount() {
    this.setLoading(true);
    await this.getAll();
    this.setFirstAsSelected();
    this.setLoading(false);
  },
};
</script>

<style></style>
